import React from "react";
import { Form } from "react-bootstrap";

type Class = { [key: string]: string };

type Task = {
  id: number;
  markup_type: string;
  state: string;
  settings: { classes: Class[] } | null;
};

const ClassSelector = ({ data, handleChange }: any) => {
  const { tasks, taskId, classname } = data;
  if (!taskId) return null;

  const selectedTask = (tasks as Task[]).filter((t) => t.id === taskId)[0];

  if (
    selectedTask.markup_type !== "classification" ||
    !selectedTask.settings ||
    !selectedTask.settings.classes ||
    !selectedTask.settings.classes.length
  )
    return null;

  return (
    <Form.Control as="select" className="mb-3" onChange={handleChange}>
      <option
        label="Выберите класс"
        value={null as any}
        selected={classname === null}
      />
      {selectedTask.settings.classes.map((batchClass) => {
        const [_, value] = Object.entries(batchClass)[0];
        return (
          <option value={value} selected={classname === value} key={value}>
            {value}
          </option>
        );
      })}
    </Form.Control>
  );
};

export default ClassSelector;
