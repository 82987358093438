import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";

type Class = { [key: string]: string };

type Task = {
  id: number;
  markup_type: string;
  state: string;
  settings: { classes: Class[] } | null;
};

const MultipleClassSelector = ({ data, handleChange }: any) => {
  const { tasks, taskId } = data;
  const [selected, setSelected] = useState<string[]>([]);

  useEffect(() => {
    setSelected([]);
  }, [taskId]);
  if (!taskId) return null;

  const selectedTask = (tasks as Task[]).filter((t) => t.id === taskId)[0];

  if (
    selectedTask.markup_type !== "multi_classification" ||
    !selectedTask.settings ||
    !selectedTask.settings.classes ||
    !selectedTask.settings.classes.length
  )
    return null;

  return (
    <FormControl fullWidth style={{ marginBottom: 15 }}>
      <InputLabel id="classes-select-label">Классы</InputLabel>
      <Select
        labelId="classes-select-label"
        value={selected}
        label="Классы"
        onChange={(e) => {
          const {
            target: { value },
          } = e;
          const selected = Array.isArray(value) ? value : [value];
          handleChange(selected.length ? selected : null);
          setSelected(selected);
        }}
        multiple
      >
        {selectedTask.settings.classes.map((batchClass) => {
          const [_, value] = Object.entries(batchClass)[0];
          return (
            <MenuItem
              value={value}
              selected={selected.includes(value)}
              key={value}
            >
              {value}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default MultipleClassSelector;
