import React from "react";
import { Form } from "react-bootstrap";
import { splitAssignedUsers } from "../utils";

const UserSelector = ({ data, handleIdChange }) => {
  const { users, tasks, userId, taskId } = data;

  let assignedUsers = users;
  if (taskId !== null && tasks !== null) {
    const task = tasks.filter((t) => t.id === taskId)[0];
    assignedUsers = splitAssignedUsers(users, task).assignedItems;
  }

  return (
    <Form.Control as="select" className="mb-3" onChange={handleIdChange}>
      <option
        label="Выберите пользователя"
        value={null}
        selected={userId === null}
      />
      {assignedUsers !== null
        ? assignedUsers.map((u) => {
            return (
              <option selected={u.id === userId} value={u.id} key={u.id}>
                {u.email}
              </option>
            );
          })
        : null}
    </Form.Control>
  );
};

export default UserSelector;
