import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  changeQueryDate,
  changeQueryTaskId,
  changeQueryUserId,
  changeQueryBatchMarkedStatus,
  changeQueryBatchClass,
  changeQueryBatchMultiClass,
  dropQuery,
  toggleDateUsage,
} from "../../../../../../actions/adminPanel";
import { adminPanelSelector } from "../../../../selectors";
import PaginatedDateSelector from "../../../common/PaginatedDateSelector";
import TaskSelector from "../../../common/TaskSelector";
import UserSelector from "../../../common/UserSelector";
import MarkedSelector from "../../../common/MarkedSelector";
import { markupQuerySelector } from "../selectors";
import DropQueryButton from "../../../common/DropQueryButton";
import ClassSelector from "../../../common/ClassSelector";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import debounce from "lodash.debounce";
import MultipleClassSelector from "../../../common/MultipleClassSelector";

const MarkupQuerySelector = () => {
  const { users, tasks } = useSelector(adminPanelSelector);
  const { taskId, userId, date, useDate, marked, classname } =
    useSelector(markupQuerySelector);
  const dispatch = useDispatch();

  const selectorData = { users, tasks, userId, taskId, marked, classname };

  const debouncedHandleClassChange = debounce((classNames) => {
    dispatch(changeQueryBatchMultiClass(classNames));
  }, 1000);

  return (
    <div className="text-center mb-3">
      <UserSelector
        data={selectorData}
        handleIdChange={(e) => {
          const userId = Number(e.target.value) || null;
          dispatch(changeQueryUserId(userId));
        }}
      />
      <TaskSelector
        data={selectorData}
        handleIdChange={(e) => {
          const taskId = Number(e.target.value) || null;
          dispatch(changeQueryTaskId(taskId));
        }}
      />
      <MarkedSelector
        data={selectorData}
        handleChange={(e) =>
          dispatch(changeQueryBatchMarkedStatus(e.target.value))
        }
      />
      <ClassSelector
        data={selectorData}
        handleChange={(e) => {
          const className = e.target.value || null;
          dispatch(changeQueryBatchClass(className));
        }}
      />
      <MultipleClassSelector
        data={selectorData}
        handleChange={debouncedHandleClassChange}
      />
      <PaginatedDateSelector
        disabled={!useDate}
        date={date}
        handleDateChange={(newDate) => dispatch(changeQueryDate(newDate))}
      />
      <FormControlLabel
        control={
          <Switch
            checked={useDate}
            onChange={(_, checked) => dispatch(toggleDateUsage(checked))}
          />
        }
        label={`Фильтр по дате ${useDate ? "включен" : "отключен"}`}
        labelPlacement="start"
        sx={{ textAlign: "left" }}
      />

      <DropQueryButton handleClick={() => dispatch(dropQuery())} />
    </div>
  );
};

export default MarkupQuerySelector;
