export const splitAssignedUsers = (users, task) => {
  const assignedUsers = [];
  const dissociatedUsers = [];
  if (!task) {
    return {
      assignedItems: [],
      dissociatedItems: users,
    };
  }
  users.forEach((u) => {
    if (u.tasks.filter((t) => t.id === task.id).length === 0) {
      dissociatedUsers.push(u);
    } else {
      assignedUsers.push(u);
    }
  });
  return {
    assignedItems: assignedUsers,
    dissociatedItems: dissociatedUsers,
  };
};

export const splitAssignedTasks = (tasks, user) => {
  const assignedTasks = [];
  const dissociatedTasks = [];
  if (!user) {
    return {
      assignedItems: [],
      dissociatedItems: tasks,
    };
  }
  tasks.forEach((t) => {
    if (user.tasks.filter((ut) => t.id === ut.id).length === 0) {
      dissociatedTasks.push(t);
    } else {
      assignedTasks.push(t);
    }
  });
  return {
    assignedItems: assignedTasks,
    dissociatedItems: dissociatedTasks,
  };
};
